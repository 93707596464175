import React from 'react'
import { ReactComponent as LogoFull } from "../../../src/assets/svg/logoFull.svg";


const NewsFooter = () => {
    return (
        <div className='flex flex-col w-full items-center  pt-2 border-t'>
            <div className='md:w-3/4 bg-white w-full '>
                <div className='flex flex-col md:flex-row justify-between items-center px-2 border-b py-2'>
                    <div>
                        <LogoFull className='w-32 md:h-32' />
                    </div>
                    <div className='flex flex-col items-center md:items-start'>
                        <p className='font-bold'>Connect with us</p>
                        <p className='text-sm'>
                            <a href="mailto:customerservice@dailystockhunt.com" className="text-blue-500 hover:underline">
                            customerservice@dailystockhunt.com
                            </a>
                        </p>
                        <p className='text-sm'><a>Call Us: +91-9440686852</a></p>
                    </div>
                </div>
                <div className='flex justify-center py-2 bg-black text-white'>
                    <p>© 2024 Daily Stock Hunt. All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}

export default NewsFooter