import "./App.css";
import { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import RenderTable from "./components/RenderTable/RenderTable";
import SideMenu from "./components/SideMenu";
import { BrowserRouter as Router, Routes, Route,useLocation } from "react-router-dom";
import StockAlert from "./components/StockAlert";
import Dashboard from "./components/Dashboard";
import { AlertContext } from "./store";
import News from "./pages/News/News";
import NotLoginDashboard from "./pages/NotLoginDashboard/NotLoginDashboard";
import axios from "axios";
import Table from "./pages/Table/Table";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Corporate from "./pages/Corporate/Corporate";
import StockInAction from "./pages/StockInAction/StockInAction";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Ads from "./components/Ads";
import HistoricData from "./pages/HistoricData/HistoricData";
import { ScrollToTop } from "./utils/utils";
import IPO from "./pages/IPO/IPO";
import IPODetail from "./pages/IPO Detail/IPODetail";
import NewsPortal from "./pages/NewsPortal/NewsHome";
import NewsNavbar from "./components/NewsPortal/NewsNavbar";
import NewsHome from "./pages/NewsPortal/NewsHome";
import Article from "./components/NewsPortal/Article";
import { RelatedStories } from "./pages/NewsPortal/data";
import RelatedStoriesCards from "./components/NewsPortal/RelatedStoriesCards";
import Loader from "./components/Loader/Loader";
import NewsFooter from "./components/NewsPortal/NewsFooter";
import { Helmet } from "react-helmet";

function LocationChecker({ setNewsLocation, setIsLoading,}) {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/news-portal")) {
      setNewsLocation(true);
    } else {
      setNewsLocation(false);
    }
    setIsLoading(false);
  }, [location, setNewsLocation, setIsLoading]);

  return null;
}


function App() {
  const [isLoggedIn, setisLoggedIn] = useState(
    Boolean(localStorage.getItem("Token"))
  );
  const [updated, setUpdated] = useState(false);
  const [sideMenuCollapsed, setSideMenuCollapsed] = useState(false);
  const [alertsArr, setAlertsArr] = useState([]);
  const [newsNotif, setNewsNotif] = useState();
  const [corporateNotif, setCorporateNotif] = useState();
  const [page, setPage] = useState(1);
  const [lastRunTime, setLastRunTime] = useState();
  const [tags, setTags] = useState();
  const [corporateTags, setCorporateTags] = useState();
  const[NewsLocation, setNewsLocation] = useState(false);
  const [count, setCount] = useState({
    corporateCount: 0,
    newsCount: 0,
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      getCommonNews();
      getCorporateNews();
      const interval = setInterval(getCommonNews, 5 * 60 * 1000);
      return () => {
        clearInterval(interval);
      };
    }, 0);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const getCommonNews = (tag) => {
    const url =
      tag?.length > 0
        ? `/api/news-feed?tag=${tag}&page_size=7&page=${page}`
        : `/api/news-feed?page=${page}&page_size=7`;

    axios
      .get(process.env.REACT_APP_BACKEND_URL + url)
      .then((res) => {
        if (res.data) {
          setNewsNotif(res?.data?.results?.new_news_tags?.length);
          setLastRunTime(res?.data?.results?.last_run);
          setTags([...res?.data?.results?.new_news_tags]);
          setCount((prevCount) => ({
            ...prevCount,
            newsCount: res?.data?.count,
          }));
        }
      })
      .catch((err) => {
        toast.error("Unable to fetch the live news at the moment!");
        console.log(err);
      });
  };

  const getCorporateNews = (tag) => {
    const url =
      tag?.length > 0
        ? `/api/nse-news?tag=${tag}&page_size=7&page=${page}`
        : `/api/nse-news?page=${page}&page_size=7`;

    axios
      .get(process.env.REACT_APP_BACKEND_URL + url)
      .then((res) => {
        if (res.data) {
          setCorporateNotif(res?.data?.results?.tags_having_news.length);
          setLastRunTime(res?.data?.results?.last_run);
          setCorporateTags([...res?.data?.results?.tags_having_news]);
          setCount((prevCount) => ({
            ...prevCount,
            corporateCount: res?.data?.count,
          }));
        }
      })
      .catch((err) => {
        toast.error("Unable to fetch the corporate news at the moment!");
        console.log(err);
      });
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#295FA6",
      },
      secondary: {
        main: "#FFF",
      },
    },
  });

  return (
    <div>
        <Helmet>
          <meta
            name="description"
            content="Your ultimate destination for real-time Indian stock market news and updates. Crafted for traders and investors, our website offers exactly what you need to stay ahead in the market. Our IPO tracker ensures you stay informed about upcoming and ongoing IPOs."
          />
        </Helmet>
      <ThemeProvider theme={theme}>

        <AlertContext.Provider
          value={{ updated, setUpdated, alertsArr, setAlertsArr }}
        >
          <Router>
            <ScrollToTop />
            <LocationChecker setNewsLocation={setNewsLocation} setIsLoading={setIsLoading} />
            {isLoading ? (
              <Loader />
            ) : (         
            <div className="app ">
              {!NewsLocation   && (
              <Navbar
                title="Stock Trading App"
                login={isLoggedIn}
                setLogin={setisLoggedIn}
              />
              )}
              {NewsLocation && (
                <>
                <NewsNavbar
                login={isLoggedIn}
                setLogin={setisLoggedIn}
                />
                {/* <RelatedStoriesCards/> */}
                <NewsFooter/>
                </>
              )}
              <div className="container-fluid mb-4">
                <div className="flex justify-end">
                {!NewsLocation   && (
                  <div className="col-md-12 w-0 md:w-[68px] md:col-md-2">
                    <SideMenu
                      newsNotif={newsNotif}
                      corporateNotif={corporateNotif}
                      isLoggedIn={isLoggedIn}
                      setisLoggedIn={setisLoggedIn}
                      setSideMenuCollapsed={setSideMenuCollapsed}
                    />
                  </div>
                )}
                  {isLoggedIn ? (
                    <Routes>
                    <Route
                        path="/"
                        element={
                          <News
                            sideMenuCollapsed={sideMenuCollapsed}
                            // setNewsNotif={setNewsNotif}
                            // lastRunTime={lastRunTime}
                            // newsTags={tags}
                            // newsCount={count.newsCount}
                          />
                        }
                      />
                      <Route
                        path="/stock-alerts"
                        element={
                          <StockAlert
                            setLogin={setisLoggedIn}
                            isLoggedIn={isLoggedIn}
                            sideMenuCollapsed={sideMenuCollapsed}
                          />
                        }
                      />
                      {/* <Route
                        path="/stock-alerts"
                        element={
                          <StockAlert
                            setLogin={setisLoggedIn}
                            isLoggedIn={isLoggedIn}
                            sideMenuCollapsed={sideMenuCollapsed}
                          />
                        }
                      /> */}
                      <Route path="/watchlist" element={<RenderTable />} />
                      <Route
                        path="/table"
                        element={
                          <Table sideMenuCollapsed={sideMenuCollapsed} />
                        }
                      />
                      <Route
                        path="/corporate"
                        element={
                          <Corporate
                            sideMenuCollapsed={sideMenuCollapsed}
                            // lastRunTime={lastRunTime}
                            // newsTags={corporateTags}
                            // corporateCount={count.corporateCount}
                          />
                        }
                      />
                      <Route
                        path="/stock-in-action"
                        element={
                          <StockInAction
                            sideMenuCollapsed={sideMenuCollapsed}
                          />
                        }
                      />
                      <Route
                        path="/historic/:symbol/:finCode"
                        element={
                          <HistoricData
                            sideMenuCollapsed={sideMenuCollapsed}
                            setisLoggedIn={setisLoggedIn}
                            isLoggedIn={isLoggedIn}
                          />
                        }
                      />
                      <Route
                        path="/ipo-details/:ipo_name"
                        element={
                          <IPODetail
                            sideMenuCollapsed={sideMenuCollapsed}
                            setisLoggedIn={setisLoggedIn}
                            isLoggedIn={isLoggedIn}
                          />
                        }
                      />
                      <Route
                        path="/ipo"
                        element={
                          <IPO
                            setLogin={setisLoggedIn}
                            isLoggedIn={isLoggedIn}
                          />
                        }
                      />
                    </Routes>
                  ) : (
                    <Routes>
                    <Route
                        path="/"
                        element={
                          <News
                            sideMenuCollapsed={sideMenuCollapsed}
                            // setNewsNotif={setNewsNotif}
                            // lastRunTime={lastRunTime}
                            // newsTags={tags}
                            // newsCount={count.newsCount}
                          />
                        }
                      />
                      {/* <Route
                        path="/"
                        element={
                          <StockAlert
                            isLoggedIn={isLoggedIn}
                            sideMenuCollapsed={sideMenuCollapsed}
                          />
                        }
                      /> */}
                      <Route
                        path="/stock-alerts"
                        element={
                          <StockAlert
                            setLogin={setisLoggedIn}
                            isLoggedIn={isLoggedIn}
                            sideMenuCollapsed={sideMenuCollapsed}
                          />
                        }
                      />
                      <Route
                        path="/table"
                        element={
                          <Table sideMenuCollapsed={sideMenuCollapsed} />
                        }
                      />
                      <Route
                        path="/corporate"
                        element={
                          <Corporate
                            lastRunTime={lastRunTime}
                            newsTags={corporateTags}
                            corporateCount={count.corporateCount}
                            sideMenuCollapsed={sideMenuCollapsed}
                          />
                        }
                      />
                      <Route
                        path="/stock-in-action"
                        element={
                          <StockInAction
                            sideMenuCollapsed={sideMenuCollapsed}
                          />
                        }
                      />
                      <Route
                        path="/historic/:symbol/:finCode"
                        element={
                          <HistoricData
                            sideMenuCollapsed={sideMenuCollapsed}
                            setisLoggedIn={setisLoggedIn}
                            isLoggedIn={isLoggedIn}
                          />
                        }
                      />
                      <Route
                        path="/ipo-details/:ipo_name"
                        element={
                          <IPODetail
                            sideMenuCollapsed={sideMenuCollapsed}
                            setisLoggedIn={setisLoggedIn}
                            isLoggedIn={isLoggedIn}
                          />
                        }
                      />
                      <Route
                        path="/ipo"
                        element={
                          <IPO
                            setLogin={setisLoggedIn}
                            isLoggedIn={isLoggedIn}
                          />
                        }
                      />
                    </Routes>
                  )}
                </div>
              </div>
            </div>     
            )}
          </Router>
        </AlertContext.Provider>
        <ToastContainer
          limit={1}
          hideProgressBar
          position="bottom-center"
          pauseOnHover
          closeButton
        />
      </ThemeProvider>
    </div>
  );
}

export default App;
