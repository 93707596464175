import React from "react";
import CommentIcon from "@mui/icons-material/Comment";
import ShareIcon from "@mui/icons-material/Share";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; // Ensure you import toast

const LatestNewsCard = (news) => {
  const navigate = useNavigate();
  const date = new Date(news.updated_at);
  const options = {
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  const formattedDate = date.toLocaleDateString("en-IN", options);

  const handleClick = () => {
    navigate(`/news-portal/news/${news.id}`);
  };

  const handleClickShare = (event) => {
    event.stopPropagation();
    try {
      const shareUrl = `${window.location.origin}/news-portal/news/${news.id}`;
      if (navigator.share) {
        navigator.share({
          image: news.image,
          title: news.title,
          text: news.description,
          url: shareUrl,
        });
      } else {
        navigator.clipboard.writeText(window.location.href);
        toast.info("Link copied to clipboard");
      }
    } catch (err) {
      console.error("Error sharing news:", err);
    }
  };

  return (
    <div
      onClick={handleClick}
      className="flex items-center justify-between py-2 px-4 bg-white cursor-pointer shadow rounded"
    >
      <div className="flex flex-col justify-between h-full py-2 border-0 w-4/5">
        <h3 className="text-lg font-semibold text-gray-800">{news.title}</h3>
        <div className="flex justify-between items-center text-gray-600">
          <div>
            <span className="text-sm mr-2">{formattedDate}</span>
          </div>
          <div className="space-x-4">
            <BookmarksIcon
              fontSize="inherit"
              className="text-gray-600 cursor-pointer"
            />
            <ShareIcon
              fontSize="inherit"
              className="text-gray-600 cursor-pointer"
              onClick={handleClickShare}
            />
          </div>
        </div>
      </div>
      <div className="w-[120px] h-[120px] ml-4 flex items-center justify-center bg-gray-100">
        {news.image ? (
          <img
            src={news.image}
            alt="news"
            className="w-full h-full object-cover rounded-lg border-0 border-black"
          />
        ) : (
          <div className="w-full h-full bg-gray-300 rounded-lg"></div>
        )}
      </div>
    </div>
  );
};

export default LatestNewsCard;
