
export const data = {
    title: "Snacks maker LetsTry raises funding from Wipro Consumer Care Ventures",
    date: "July 04,2024",
    description: "Wipro Consumer Care Ventures, 9Unicorns, et al invest undisclosed amount in packaged snacks company LetsTry.",
    content: "Wipro Consumer Care Ventures, the venture capital arm of Wipro Consumer Care & Lighting, has invested in LetsTry, a packaged snacks firm.9Unicorns, an existing investor, also participated. The companies did not reveal the amount invested.“Their deep understanding of product innovation, consumer behaviour, and market trends uniquely positions them to expand rapidly, and challenge established brands. This marks our 12th investment from our fund and second in the food sector,” said Sumit Keshan, Managing Partner, Wipro Consumer Care Ventures. Delhi-NCR company LetsTry was founded in 2021 by Nitin Kalra. “The current funding round and partnership with Wipro Consumer Care will unlock new growth avenues for LetsTry. We aim to leverage these opportunities to propel LetsTry towards becoming one of India’s most promising brands,” Kalra said. The brand said its rapid market penetration and growth trajectory underscore its commitment to quality and innovation in the snacking industry. Direct-to-consumer or D2C food brands have seen a flurry of investments in recent times. In April, Adukale raised ₹11 crore in its pre-Series A round led by Force Ventures, backed by marquee investors like Aanya Ventures, Subrata Mitra, and Radhika Pandit.",
    author: "BY R. BENGALURU BUREAU",
    companies: ["Wipro Ltd"]
  };
  
  export const relatedTopics = ["Wipro Ltd", "Venture Capital", "Food Processing Industry"];
  
  export const trendingCompanies = [
    "Tata Motors Share Price", "Hdfc Bank Share Price", "Reliance Industries Share Price",
    "Tata Steel Share Price", "Icici Bank Share Price", "Itc Share Price",
    "Infosys Share Price", "Sbi Share Price", "Tata Consultancy Services"
  ];
  
  export const RelatedStories = [
    {
      title: "Wipro Consumer Care Ventures invests in LetsTry",
      subHeading: "Sub Heading",
      image: "https://images.unsplash.com/photo-1504711434969-e33886168f5c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
    },
    {
      title: "Wipro Consumer Care Ventures invests in LetsTry",
      subHeading: "Sub Heading",
      image: "https://images.unsplash.com/photo-1504711434969-e33886168f5c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
    },
    {
      title: "Wipro Consumer Care Ventures invests in LetsTry",
      subHeading: "Sub Heading",
      image: "https://images.unsplash.com/photo-1504711434969-e33886168f5c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
    }
  ];
  
  export const news = [
    {
        title: "Snacks maker LetsTry raises funding from Wipro Consumer Care Ventures",
        time: "06:08 PM IST",
        readTime: "2 min read"
    },
    {
        title: "Snacks maker LetsTry raises funding from Wipro Consumer Care Ventures",
        time: "06:08 PM IST",
        readTime: "2 min read"
    },
    {
        title: "Snacks maker LetsTry raises funding from Wipro Consumer Care Ventures",
        time: "06:08 PM IST",
        readTime: "2 min read"
    },
    {
        title: "Snacks maker LetsTry raises funding from Wipro Consumer Care Ventures",
        time: "06:08 PM IST",
        readTime: "2 min read"
    },
    {
        title: "Snacks maker LetsTry raises funding from Wipro Consumer Care Ventures",
        time: "06:08 PM IST",
        readTime: "2 min read"
    },
    {
        title: "Snacks maker LetsTry raises funding from Wipro Consumer Care Ventures",
        time: "06:08 PM IST",
        readTime: "2 min read"
    },

  ];
  