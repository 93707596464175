import React, { useEffect, useState } from "react";
import StockCard from "../pages/StockInAction/StockCard";
import { Button, Pagination, Skeleton } from "@mui/material";
import CustomChip from "./CustomChip";
import { toast } from "react-toastify";
import Loader from "./Loader/Loader";
import axios from "axios";

const apiHost = process.env.REACT_APP_BACKEND_URL;
const PAGE_SIZE = 18;
const StockInNews = () => {
  const [totalNews, setTotalNews] = useState(null);
  const [cards, setCards] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);

  useEffect(() => {
    fetchData();
  }, [currentPage, filter]);

  const fetchData = async () => {
    if (loadMore) {
      setLoading(true);
    }
    try {
      let apiUrl = `${apiHost}/api/stock-in-action?page=${currentPage}&page_size=${PAGE_SIZE}&ordering=${
        filter === "gain"
          ? "-percentage"
          : filter === "loss"
          ? "percentage"
          : ""
      }`;

      // Add filter to the API URL if a filter is selected
      if (filter) {
        apiUrl += `&stock_type=${filter}`;
      }

      const authToken = JSON.parse(localStorage.getItem("Token"));

      const headers = {
        "Content-Type": "application/json",
      };

      // Include the token in the request headers if it exists
      if (authToken) {
        headers["Authorization"] = `Bearer ${authToken}`;
      }

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: headers,
      });

      const data = await response.json();
      // console.log(data);

      setTotalNews(data?.count);
      setPageCount(Math.ceil(data?.count / PAGE_SIZE)); // page count based on total news and items per page

      const { results } = data;

      const cardsData = results.map((item) => ({
        title: item.company_name,
        price: `₹${item.price}`,
        profit: item.stock_type === "gain" ? item.percentage : null,
        loss: item.stock_type === "loss" ? item.percentage : null,
        news: item.news.summary,
        link: item.news.link,
        symbol: item.symbol,
        inWatchlist: item.added_in_watchlist,
      }));

      if (loadMore) {
        setCards((prev) => [...prev, ...cardsData]);
        setFilterLoader(false);
      } else {
        setCards(cardsData);
        setFilterLoader(false);
      }
      setLoading(false);
    } catch (error) {
      setFilterLoader(false);
      setLoading(false);
      console.error("Error fetching data:", error);
      toast.error("Unable to fetch Stock in news at the moment!");
    }
  };

  const handlePageChange = () => {
    setCurrentPage((prev) => prev + 1);
    setLoadMore(true);
  };

  const handleChipClick = (selectedFilter) => {
    setCurrentPage(1); // Reset page to 1 when applying a new filter
    setFilter(selectedFilter); // Set the filter value
    setLoadMore(false);
    setFilterLoader(true);
  };

  // console.log(currentPage);

  return (
    <>
      <div className="chip-container flex gap-2 mt-4">
        <CustomChip
          title={"All"}
          selected={filter === ""}
          onClick={() => handleChipClick("")}
        />
        <CustomChip
          title={"Gains"}
          selected={filter === "gain"}
          onClick={() => handleChipClick("gain")}
        />
        <CustomChip
          title={"Losses"}
          selected={filter === "loss"}
          onClick={() => handleChipClick("loss")}
        />
      </div>
      {filterLoader ? (
         <div className="flex gap-4 flex-wrap mt-4">

         {Array.from({ length: 9 }, (_, index) => (
          <div className="h-[180px] w-[228px] border border-1 rounded pt-2 pb-0 overflow-hidden">
          <Skeleton className="ml-3" key={index} variant="square" animation="wave" width={200} height={20} />
          <Skeleton className="ml-3 mt-8 mb-3" key={index} variant="square" animation="wave" width={80} height={15} />
          <div className="flex justify-between items-center">
          <Skeleton className="ml-3" key={index} variant="square" animation="wave" width={80} height={20} />
          <Skeleton className="mr-4"  key={index} variant="circular" animation="wave" width={20} height={20} />
        </div>
        <Skeleton className="mt-6" key={index} variant="square" animation="wave" width={228} height={60} />
        </div>
        ))}
    </div>
      ) : (
        <>
          <div className="card-container mt-4 flex gap-4 flex-wrap">
            {cards.length > 0 ? cards.map((card, index) => (
              <StockCard key={index} data={card} />
            ))

          : 
         // Render 9 skeleton loaders
         Array.from({ length: 9 }, (_, index) => (
          <div className="h-[180px] w-[228px] border border-1 rounded pt-2 pb-0 overflow-hidden">
          <Skeleton className="ml-3" key={index} variant="square" animation="wave" width={200} height={20} />
          <Skeleton className="ml-3 mt-8 mb-3" key={index} variant="square" animation="wave" width={80} height={15} />
          <div className="flex justify-between items-center">
          <Skeleton className="ml-3" key={index} variant="square" animation="wave" width={80} height={20} />
          <Skeleton className="mr-4"  key={index} variant="circular" animation="wave" width={20} height={20} />
        </div>
        <Skeleton className="mt-6" key={index} variant="square" animation="wave" width={228} height={60} />
        </div>
        ))
          }
          </div>
          <div className="flex justify-center mt-4">
            {currentPage < Math.ceil(totalNews / PAGE_SIZE) && !loading && (
              // <Pagination
              //   count={pageCount}
              //   page={currentPage}
              //   onChange={handlePageChange}
              //   color="secondary"
              // />
              <Button variant="contained" onClick={handlePageChange}>
                Load More
              </Button>
            )}
          </div>
        </>
      )}
      {loading && 
      <div className="flex gap-4 flex-wrap">

           {Array.from({ length: 9 }, (_, index) => (
            <div className="h-[180px] w-[228px] border border-1 rounded pt-2 pb-0 overflow-hidden">
            <Skeleton className="ml-3" key={index} variant="square" animation="wave" width={200} height={20} />
            <Skeleton className="ml-3 mt-8 mb-3" key={index} variant="square" animation="wave" width={80} height={15} />
            <div className="flex justify-between items-center">
            <Skeleton className="ml-3" key={index} variant="square" animation="wave" width={80} height={20} />
            <Skeleton className="mr-4"  key={index} variant="circular" animation="wave" width={20} height={20} />
          </div>
          <Skeleton className="mt-6" key={index} variant="square" animation="wave" width={228} height={60} />
          </div>
          ))}
      </div>
      }
    </>
  );
};

export default StockInNews;
