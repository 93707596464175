import React, { useState, useEffect, useCallback } from "react";
import { NavLink, Router, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { ReactComponent as LogoFull } from "../../../src/assets/svg/logoFull.svg";
import NewsTicker from "./NewsTicker";
import NewsAds from "./NewsAds";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Menu from "./Menu";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { toast } from "react-toastify";
import { generateToken } from "../../utils/generateWatchlistToken";
import GoogleIcon from "../Icons/GoogleIcon";
import LogoutIcon from "../Icons/LogoutIcon";
import NewsContainer from "../../pages/NewsPortal/NewsContainer";
import { Routes, Route } from "react-router-dom";
import Article from "../../components/NewsPortal/Article";
import Sidebar from "./SideBar";
import ArrowButton from "../../assets/svg/arrow.svg";
import Loader from "../Loader/Loader";
import YoutubeEmbed from "./YoutubeEmbed";

const apiHost = process.env.REACT_APP_BACKEND_URL;

const SearchInput = ({ value, onChange }) => (
  <div className="flex items-center border rounded-full px-2 py-1">
    <SearchIcon />
    <input
      type="text"
      placeholder="Search"
      className="border-none outline-none ml-2"
      value={value}
      onChange={onChange}
    />
  </div>
);

function NewsNavbar(props) {
  const location = useLocation();
  const [value, setValue] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newsTabs, setNewsTabs] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [label, setLabel] = useState("");
  const [dataFetched, setDataFetched] = useState(false);

  let isLoggedIn = props.login;
  let setisLoggedIn = props.setLogin;

  useEffect(() => {
    // Check if the token has expired when the component mounts
    const token = localStorage.getItem("Token");
    if (token) {
      const decodedToken = parseJwt(token);
      if (decodedToken.exp && decodedToken.exp * 1000 < Date.now()) {
        // Token has expired, log the user out
        localStorage.clear();
        setisLoggedIn(false);
      }
    }
  }, [setisLoggedIn]);

  useEffect(() => {
    const path = location.pathname.split("/").slice(0, 3).join("/");
    const index = newsTabs.findIndex((tab) => tab.path === path);
    setValue(index >= 0 ? index : false);
  }, [location.pathname, newsTabs]);

  useEffect(() => {
    getTabs();
    fetchNews();
  }, []);

  const getTabs = async () => {
    try {
      const response = await axios.get(`${apiHost}/api/portal-news-tags`);
      console.log(response.data);
      const sortedTabs = response.data.sort((a, b) => a.order - b.order);
      const tabs = sortedTabs.map((tab) => ({
        label: tab.tag,
        path: `/news-portal/${createSlug(tab.tag)}`,
      }));
      tabs.unshift({ label: "Latest News", path: "/news-portal/latest-news" });
      setNewsTabs(tabs);
    } catch (error) {
      console.error("Error fetching news tabs", error);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        axios
          .post(apiHost + "/auth/google/", {
            access_token: response.access_token,
          })
          .then((res) => {
            if (res.data) {
              localStorage.setItem(
                "Token",
                JSON.stringify(res.data.access_token)
              );
              localStorage.setItem(
                "Username",
                JSON.stringify(
                  res.data.user.first_name + " " + res.data.user.last_name
                )
              );
              localStorage.removeItem("TempToken");
              generateToken();
              return res;
            }
          })
          .then((res) => {
            setisLoggedIn(true);
          })
          .catch((err) => {
            toast.error("Something went wrong");
            console.log(err);
          });
      } catch (error) {
        toast.error("Something went wrong");
        console.log(error);
      }
    },
  });

  const logout = () => {
    localStorage.clear();
    setisLoggedIn(false);
  };

  const createSlug = (text) => {
    return text
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  const SubscribeSignInButtons = () => (
    <div className="flex items-center space-x-4">
      {/* <button className='bg-black text-white px-4 py-1 text-sm rounded-md'>Subscribe</button> */}
      {isLoggedIn ? (
        <div className="dropdown">
          <button
            className="dropdown-toggle btn bg-white text-black px-4 py-1 text-sm rounded-md border border-black"
            data-bs-toggle="dropdown"
          >
            {JSON.parse(localStorage.getItem("Username"))}
          </button>
          <ul
            onClick={logout}
            className="dropdown-menu hover:bg-hoveredWhite cursor-pointer text-black px-4 py-1 text-sm rounded-md border border-black"
          >
            <li className="text-sm flex justify-start gap-2 items-center">
              <LogoutIcon inherit />
              <button className="cursor-pointer">Logout</button>
            </li>
          </ul>
        </div>
      ) : (
        <button
          className="bg-white flex space-x-2 text-black px-4 py-1 text-sm rounded-md border border-black"
          onClick={googleLogin}
        >
          <GoogleIcon />
          <div>Login</div>
        </button>
      )}
    </div>
  );

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString("en-IN", options);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setLabel(newsTabs[newValue].label);
  };

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  const handleSearch = async (query) => {
    if (query.length > 2) {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiHost}/api/portal-news?search=${query}`
        );
        setSearchResults(response.data.results);
      } catch (error) {
        console.error("Error fetching search results", error);
        toast.error("Error fetching search results");
      } finally {
        setLoading(false);
      }
    } else {
      setSearchResults([]);
    }
  };

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 300), []);

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedHandleSearch(query);
  };

  const handleClick = (id) => () => {
    setSearchQuery("");
    setSearchResults([]);
    window.location.href = `/news-portal/news/${id}`;
  };

  const fetchNews = async () => {
    try {
      const response = await axios.get(`${apiHost}/api/portal-news`);
      setNewsData(response.data.results);
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setDataFetched(true);
    }
  };

  return (
    <div className="flex flex-col w-full items-center bg-grey">
      {/* <NewsAds width="100%" height="90px" /> */}
      <div className="md:h-[90px] w-full flex flex-col items-center justify-center mt-4">
        <div className="md:h-full flex items-center justify-between md:w-3/4 w-full px-4 md:px-4 bg-white">
          <div className="hidden md:flex flex-col justify-around items-start h-full">
            {/* <MenuIcon onClick={toggleMenu} style={{ cursor: 'pointer' }} aria-label="Menu" /> */}
            <SearchInput value={searchQuery} onChange={handleSearchChange} />
            <div
              className="relative inline-flex items-center justify-center w-48 h-6 mt-2 cursor-pointer"
              onClick={() => (window.location.href = "/")}
            >
              <img
                src={ArrowButton}
                alt="arrow"
                className="absolute inset-0 w-full h-full object-cover"
              />
              <p className="relative text-white z-10 text-xs">
                Back To Main Site
              </p>
            </div>
          </div>
          <div className="flex items-center">
            <LogoFull height={80} />
          </div>
          <SubscribeSignInButtons />
        </div>
        <div className="flex md:hidden w-full bg-white justify-between pb-2 px-2 items-center">
          <div
            className="relative inline-flex items-center justify-center w-48 h-6 cursor-pointer"
            onClick={() => (window.location.href = "/")}
          >
            <img
              src={ArrowButton}
              alt="arrow"
              className="absolute inset-0 w-full h-full object-cover"
            />
            <p className="relative text-white z-10 text-xs">Back To Main</p>
          </div>
          <SearchInput value={searchQuery} onChange={handleSearchChange} />
        </div>
      </div>
      {searchQuery.length > 2 && (
        <div className="absolute mt-36 md:mt-16 bg-white border rounded shadow-lg w-3/4 z-40">
          {loading ? (
            <div className="p-2">Loading...</div>
          ) : searchResults.length > 0 ? (
            searchResults.map((result, index) => (
              <div
                key={index}
                className="p-2 hover:bg-gray-200 cursor-pointer flex justify-between"
                onClick={handleClick(result.id)}
              >
                <div className="flex flex-col justify-between">
                  <p>{result.title}</p>
                  <p className="text-xs text-gray-500">
                    {formatDate(result.updated_at)}
                  </p>
                </div>
                <img
                  src={result.image}
                  alt={result.title}
                  className="w-20 h-20"
                />
              </div>
            ))
          ) : (
            <div className="p-2">No results found</div>
          )}
        </div>
      )}
      <div className="mt-2 md:w-3/4 w-[95%]">
        <NewsTicker />
      </div>
      <div className="md:w-3/4 w-[95%] border-b mt-2 bg-white">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="news navigation tabs"
        >
          {newsTabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              component={NavLink}
              to={tab.path}
              value={index}
            />
          ))}
        </Tabs>
      </div>
      {/* {isNewsContainerVisible && <NewsContainer label={label} newsData={newsData} />} */}
      <div className="md:w-3/4 w-[95%] mt-2 h-full mb-4 min-h-[800px]">
        <div className="w-full mb-4 flex justify-between">
          {!dataFetched ? (
            <Loader />
          ) : (
            <Routes>
              {/* <Route path="/news-portal" element={<NewsHome/>} /> */}
              <Route
                path="/news-portal/news/:newsId"
                element={<Article login={isLoggedIn} />}
              />
              <Route
                path="/news-portal"
                element={
                  <NewsContainer newsTabs={newsTabs} newsData={newsData} />
                }
              />
              <Route
                path="/news-portal/:path"
                element={
                  <NewsContainer newsTabs={newsTabs} newsData={newsData} />
                }
              />
            </Routes>
          )}
          <Sidebar newsData={newsData} />
        </div>
      </div>
      <Menu
        login={isLoggedIn}
        setLogin={setisLoggedIn}
        logout={logout}
        isOpen={isMenuOpen}
        googleLogin={googleLogin}
        onClose={() => setIsMenuOpen(false)}
      />
    </div>
  );
}

export default NewsNavbar;
