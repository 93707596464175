import React, { useEffect, useState } from "react";
import "./Table.css";
import axios from "axios";
import spinner from "../../assets/svg/spinner.gif";
import Minimize from "../../assets/svg/minimize.png";
import Maximize from "../../assets/svg/maximize.png";
import DealsTable from "./DealsTable";
import { toast } from "react-toastify";
import DownArrow from "../../components/Icons/DownArrow";
import Ads from "../../components/Ads";
import { Skeleton, Tab, Tabs } from "@mui/material";
import IPO from "../IPO/IPO";
import ASM from "../../components/ASM/ASM";

function Table({ sideMenuCollapsed }) {
  const [bulkCsv, setBulkCsv] = useState();
  const [bulkCsvBackup, setBulkCsvBackup] = useState();
  const [blockCsv, setBlockCsv] = useState();
  const [blockCsvBackup, setBlockCsvBackup] = useState();
  const [week52HighLow, setWeek52HighLow] = useState();
  const [priceBandChange, setPriceBandChange] = useState();
  const [priceBandChangeBackup, setPriceBandChangeBackup] = useState();
  const [announcementData, setAnnouncementData] = useState();
  const [announcementDataBackup, setAnnouncementDataBackup] = useState();
  const [meetingPurpose, setMeetingPurpose] = useState();
  const [meetingPurposeBackup, setMeetingPurposeBackup] = useState();
  const [loading, setLoading] = useState();
  const [searchField, setSearchField] = useState();
  const [expand1, setExpand1] = useState(false);
  const [expand2, setExpand2] = useState(false);
  const [expand3, setExpand3] = useState(false);
  const [expand4, setExpand4] = useState(false);
  const [expand5, setExpand5] = useState(false);
  const [selectedTab, setSelectedTab] = useState("bulk_deals_block_deals");
  const [selectedToggle, setSelectedToggle] = useState(false);
  const [asmSearch, setAsmSearch] = useState(false);

  const handleToggle = () => {
    setSelectedToggle(!selectedToggle);
  };

  useEffect(() => {
    getTableData();
  }, [selectedToggle]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue); // Update the selected tab when changed
  };
  const getTableData = () => {
    setLoading(true);
    const URL = selectedToggle
      ? process.env.REACT_APP_BACKEND_URL + "/api/csv-data?deal_type=bse"
      : process.env.REACT_APP_BACKEND_URL + "/api/csv-data";
    axios
      .get(URL)
      .then((res) => {
        console.log("API Response---------", res.data); 
        if (res?.data) {
          setBulkCsv([...res?.data?.bulk_csv]);
          setBulkCsvBackup([...res?.data?.bulk_csv]);
          setBlockCsv([...res?.data?.block_csv]);
          setBlockCsvBackup([...res?.data?.block_csv]);
          setWeek52HighLow([...res?.data?.week_52_high_low]);
          setPriceBandChange([...res?.data?.price_band_changes]);
          setPriceBandChangeBackup([...res?.data?.price_band_changes]);
          setAnnouncementData([...res?.data?.announcement_data]);
          setAnnouncementDataBackup([...res?.data?.announcement_data]);
          setMeetingPurpose([...res?.data?.meeting_purpose_data]);
          setMeetingPurposeBackup([...res?.data?.meeting_purpose_data]);
          setLoading(false);
        }
      })
      .catch((err) => {
        // toast.error("Failed to load tables at the moment!");
        console.log("API Error-------", err); 
        console.log(err);
        setLoading(false);
      });
  };

  // Function to check if the search text matches any of the provided data fields
  const filteredSearch = (data, search) => {
    return (
      data?.symbol?.toLowerCase().includes(search.toLowerCase()) || // Check symbol field
      data?.security_name?.toLowerCase().includes(search.toLowerCase()) || // Check security name field
      data?.client_name?.toLowerCase().includes(search.toLowerCase()) // Check client name field
    );
  };

  // Function to filter and update table data based on the search text
  const searchTableFunction = (search) => {
    // If search is empty, reset table data to their original state
    if (!search || search === "" || search === null) {
      setBulkCsv([...bulkCsvBackup]);
      setBlockCsv([...blockCsvBackup]);
      setPriceBandChange([...priceBandChangeBackup]);
    } else {
      // Filter and update table data for each category based on the search text

      // Filter Bulk CSV data
      const BulkFilteredData = bulkCsvBackup?.filter((data) =>
        filteredSearch(data, search)
      );

      // Filter Block CSV data
      const BlockFilteredData = blockCsvBackup?.filter((data) =>
        filteredSearch(data, search)
      );

      // Filter Price Band Change data
      const PriceBandChangeFilteredData = priceBandChangeBackup?.filter(
        (data) => filteredSearch(data, search)
      );

      // Filter Announcement data
      const AnnouncementFilteredData = announcementDataBackup?.filter((data) =>
        filteredSearch(data, search)
      );

      // Filter Meeting Purpose data
      const MeetingFilteredData = meetingPurposeBackup?.filter((data) =>
        filteredSearch(data, search)
      );

      // Update the state with filtered data for each category
      setBulkCsv(BulkFilteredData);
      setBlockCsv(BlockFilteredData);
      setPriceBandChange(PriceBandChangeFilteredData);
      setAnnouncementData(AnnouncementFilteredData);
      setMeetingPurpose(MeetingFilteredData);
    }
  };

  //Titles for Deals Table Header
  const dealsTableHeader = [
    "Date",
    !selectedToggle && "Symbol",
    "Security Name",
    "Client Name",
    "Buy/Sell",
    "Quantity",
    "Price",
  ];

  //Title for Price Band Change Table
  const priceBandChangeHeader = [
    "Date",
    "Symbol",
    "Security Name",
    "Series",
    "Change from",
    "Change To",
  ];

  //Titles for Meeting Purpose Table
  const meetingTableHeader = [
    "bc_end_dt",
    "bc_start_dt",
    "ex_dt",
    "symbol",
    "security",
    "series",
    "purpose",
    "record_dt",
  ];

  let isSearchBar = false; //flag whether the searchBar should be visible or not in the table

  return (
    <>
      <div
        className={`w-full ${
          sideMenuCollapsed ? "md:w-[88%]" : "md:w-[65.75%]"
        }  p-2 md:p-4 pe-0 mt-16 md:mt-0 d-flex flex-column gap-4 transition-all duration-500`}
      >
        {/* <div className="">
          <h5 className="text-xl text-primaryBrown">BULK/BLOCK DEALS</h5>
        </div> */}
        <div className="flex justify-between flex-wrap-reverse gap-2 items-center w-full">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="Report Tables"
            indicatorColor="primary"
            sx={{ height: "30px", borderBottom: "1px solid lightgray" }}
          >
            <Tab
              label="Bulk/Block Deals"
              value="bulk_deals_block_deals"
              className={
                selectedTab === "bulk_deals_block_deals" ? "selected" : ""
              }
              // icon={<NewspaperIcon />}
              iconPosition="start"
            />

            <Tab
              label="Price Band Change"
              value="price_band_change"
              className={selectedTab === "price_band_change" ? "selected" : ""}
              // icon={<TagIcon />}
              iconPosition="start"
            />

            <Tab
              label="ASM/GSM-Active"
              value="asm"
              className={selectedTab === "asm" ? "selected" : ""}
              // icon={<TagIcon />}
              iconPosition="start"
            />
            <Tab
              label="ASM/GSM-Exited"
              value="asm-exited"
              className={selectedTab === "asm-exited" ? "selected" : ""}
              // icon={<TagIcon />}
              iconPosition="start"
            />
          </Tabs>

          <div className=" d-flex w-full md:w-48">
            <div className="input-group relative flex items-center w-full">
              <input
                onChange={(e) => {
                  searchTableFunction(e.target.value);
                  setSearchField(e.target.value);
                }}
                type="search"
                className=" p-2 px-3 rounded-pill !pr-8 outline-none border w-full border-black "
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search-addon"
              />
              {selectedTab === "asm" && (
                <i
                  className="fa fa-search absolute right-4 z-10 cursor-pointer"
                  onClick={() => {
                    searchTableFunction(searchField);
                    setAsmSearch(true);
                  }}
                  type="button"
                ></i>
              )}
            </div>
          </div>
        </div>

        {/* Bulk Deals and Block Deals Table */}
        {selectedTab === "bulk_deals_block_deals" ? (
          <>
            <DealsTable
              title="Bulk Deals"
              expand={expand1}
              setExpand={setExpand1}
              loading={loading}
              csvData={bulkCsv}
              dealsTableHeader={dealsTableHeader}
              searchTableFunction={searchTableFunction}
              searchField={searchField}
              Minimize={Minimize}
              Maximize={Maximize}
              spinner={spinner}
              isSearchBar={true}
              handleToggle={handleToggle}
              selectedToggle={selectedToggle}
              isToggle
            />

            <DealsTable
              title="Block Deals"
              expand={expand2}
              setExpand={setExpand2}
              loading={loading}
              csvData={blockCsv}
              dealsTableHeader={dealsTableHeader}
              searchTableFunction={searchTableFunction}
              searchField={searchField}
              Minimize={Minimize}
              Maximize={Maximize}
              spinner={spinner}
              isSearchBar={false}
            />
          </>
        ) : selectedTab === "asm" ? (
          <ASM
            searchTerm={searchField}
            asmSearch={asmSearch}
            setAsmSearch={setAsmSearch}
            type={"asm-active"}
          />
        ) : selectedTab === "asm-exited" ? (
          <ASM
            searchTerm={searchField}
            asmSearch={asmSearch}
            setAsmSearch={setAsmSearch}
            type={"asm-exited"}
          />
        ) : (
          //  Price Band Change
          <div className="table-row">
            <div className="bulk-csv d-flex flex-column mt-3">
              <div className="title">
                <h1 className="text-primaryBrown">Price Band Change</h1>
                <div
                  className={`cursor-pointer transform transition-transform duration-300 h-4 ${
                    expand3 ? "rotate-180" : "rotate-0"
                  }`}
                  onClick={() => {
                    setExpand3(!expand3);
                  }}
                >
                  <DownArrow />
                </div>
              </div>

              <div
                className="table-content"
                style={expand3 ? { height: "auto" } : { maxHeight: "200px" }}
              >
                <table>
                  <thead className="bg-primaryHovered text-white">
                    <tr>
                      {priceBandChangeHeader?.map((title, index) => (
                        <th key={index}>{title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <>
                        {Array.from({ length: 4 }, (_, rowIndex) => (
                          <tr key={rowIndex}>
                            {priceBandChangeHeader?.map((_, colIndex) => (
                              <td key={colIndex}>
                                <Skeleton
                                  animation="wave"
                                  variant="rect"
                                  height={20}
                                  width={100}
                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </>
                    ) : (
                      priceBandChange?.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            style={
                              index % 2
                                ? { backgroundColor: "rgb(246,246,246)" }
                                : {}
                            }
                            className=" text-primaryText"
                          >
                            <td>{data?.date}</td>
                            <td>{data?.symbol}</td>
                            <td>{data?.security_name}</td>
                            <td>{data?.series}</td>
                            <td>{data?.change_from}</td>
                            <td>{data?.change_to}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {/* Announcement Data Table */}
        {/* <div className="table-row">
          <div className="bulk-csv d-flex flex-column mt-3">
            <div className="title">
              <h1 className="text-primaryBrown">Announcement Data</h1>
              <div
                className={`cursor-pointer transform transition-transform duration-300 h-4 ${
                  expand4 ? "rotate-180" : "rotate-0"
                }`}
                onClick={() => {
                  setExpand4(!expand4);
                }}
              >
                <DownArrow />
              </div>
            </div>

            <div
              className="table-content"
              style={expand4 ? { height: "auto" } : { maxHeight: "200px" }}
            >
              <table>
                <thead>
                  <tr>
                    <th>Symbol</th>
                    <th>Company_name</th>
                    <th>Accouncements</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={"6"}>
                        <Skeleton
                          animation="wave"
                          variant="square"
                          height={120}
                          width={"100%"}
                        />
                      </td>
                    </tr>
                  ) : (
                    announcementData?.map((data, index) => {
                      return (
                        <tr
                          style={
                            index % 2
                              ? { backgroundColor: "rgb(246,246,246)" }
                              : {}
                          }
                          className=" text-primaryText"
                        >
                          <td>{data?.symbol}</td>
                          <td>{data?.company_name}</td>
                          <td>{data?.accouncements}</td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div> */}

        {/* Metting Purpose Table */}
        {/* <div className="table-row">
          <div className="bulk-csv d-flex flex-column mt-3">
            <div className="title">
              <h1 className="text-primaryBrown">Meeting Purpose</h1>
              <div
                className={`cursor-pointer transform transition-transform duration-300 h-4 ${
                  expand5 ? "rotate-180" : "rotate-0"
                }`}
                onClick={() => {
                  setExpand5(!expand5);
                }}
              >
                <DownArrow />
              </div>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <div
                className="table-content"
                style={expand5 ? { height: "auto" } : { maxHeight: "200px" }}
              >
                <table>
                  <thead>
                    <tr>
                      {meetingTableHeader.map((title, index) => (
                        <th key={index}>{title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {meetingPurpose?.map((data, index) => {
                      return (
                        <tr
                          style={
                            index % 2
                              ? { backgroundColor: "rgb(246,246,246)" }
                              : {}
                          }
                          className=" text-primaryText"
                        >
                          <td>{data?.bc_end_dt}</td>
                          <td>{data?.bc_start_dt}</td>
                          <td>{data?.ex_dt}</td>
                          <td>{data?.symbol}</td>
                          <td>{data?.security}</td>
                          <td>{data?.series}</td>
                          <td>{data?.purpose}</td>
                          <td>{data?.record_dt}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div> */}
      </div>
      <div className="hidden md:block mt-2">
        <Ads />
      </div>
    </>
  );
}

export default Table;
