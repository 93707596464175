import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CommentIcon from "@mui/icons-material/Comment";
import ShareIcon from "@mui/icons-material/Share";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import NewsAds from "./NewsAds";
import { apiUrl } from "../../utils/utils";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { relatedTopics, trendingCompanies } from "../../pages/NewsPortal/data";
import Sidebar from "./SideBar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import RelatedNews from "../RelatedNews/RelatedNews";

const Article = (props) => {
  const { newsId } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [comment, setComment] = useState("");
  const [newsData, setNewsData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const commentsRef = useRef(null);
  let isLoggedIn = props.login;

  useEffect(() => {
    fetchArticle();
    fetchNews();
  }, [newsId]);

  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("Token"))}`,
    },
  };

  const fetchNews = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/portal-news`);
      const filteredNews = response.data.results
        .filter((news) => news.id !== newsId)
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .slice(0, 4);

      setNewsData(filteredNews);
      console.log("filteredNews", filteredNews);
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setDataFetched(true);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString("en-IN", options);
  };

  const fetchArticle = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/portal-news/${newsId}`);
      console.log(response.data);
      setData(response.data);
    } catch (error) {
      setError("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  const handleAddComment = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/api/comment/`,
        {
          id: newsId,
          content_type: "portal-news",
          text: comment,
        },
        config
      );
      fetchArticle();
      setShowCommentInput(false);
      setComment("");
    } catch (error) {
      console.error("Error adding comment", error);
    }
  };

  const handelCommentClick = () => {
    if (!isLoggedIn) {
      toast.error("Please login to comment");
    } else {
      setShowCommentInput(!showCommentInput);
      setComment("");
    }
  };

  const scrollToComments = () => {
    commentsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleShareClick = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: data.title,
          text: data.summary,
          url: window.location.href,
        });
      } else {
        // Fallback for browsers that do not support navigator.share
        navigator.clipboard.writeText(window.location.href);
        toast.info("Link copied to clipboard");
      }
    } catch (error) {
      console.error("Error sharing:", error);
      toast.error("Error sharing article");
    }
  };

  if (loading) {
    return (
      <div className="w-[78%] mt-4 min-h-[800px]">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!data) {
    return <div>No data found</div>;
  }
  return (
    <div className="md:w-[78%] w-full flex flex-col space-y-4 bg-white p-4 rounded">
      <Helmet>
        <title>{data.title}</title>
        <meta name="description" content={data.summary} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={data.title} />
        <meta property="og:description" content={data.summary} />
        <meta property="og:image" content={data.image} />
        <meta
          property="og:url"
          content={`${window.location.origin}/news-portal/news/${data.id}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={data.title} />
        <meta name="twitter:description" content={data.summary} />
        <meta name="twitter:image" content={data.image} />
      </Helmet>

      <div>
        <h2 className="text-3xl font-semibold mb-2 text-blue-900">
          {data.title}
        </h2>
        <p
          className="text-md my-8 bg-gray-200 px-4 py-3 italic border-l-4 border-black"
          dangerouslySetInnerHTML={{ __html: data.summary }}
        />
        <p className="text-xs text-gray-500 mb-1 font-semibold">
          {data.author}
        </p>
        <p className="text-xs text-gray-500 mb-4">
          {formatDate(data.updated_at)}
        </p>

        {/* <div className="flex items-center mb-4">
                    <span className="text-sm mr-2">Companies to follow</span>
                    {relatedTopics.map((company, index) => (
                        <span key={index} className="px-2 py-1 text-xs font-bold border border-grey-400 rounded-sm mr-2">
                            {company} +
                        </span>
                    ))}
                </div> */}
        <div className="flex justify-between items-center mt-12">
          <div className="flex space-x-3">
            <button
              className="flex items-center text-gray-500 text-sm"
              onClick={scrollToComments}
            >
              <CommentIcon /> COMMENTS
            </button>
            <button
              className="flex items-center text-gray-500 text-sm"
              onClick={handleShareClick}
            >
              <ShareIcon /> SHARE
            </button>
          </div>
          <div>
            <button className="flex items-center text-gray-500 text-sm">
              <BookmarksIcon />
              READ LATER
            </button>
          </div>
        </div>
      </div>
      <div className="my-4 ">
        <img
          src={data.image}
          alt={data.title}
          className="w-full h-96 object-cover rounded-md"
        />
        {data.image_description && (
          <p className="text-xs text-gray-500 py-2 ">
            {data.image_description}
          </p>
        )}
      </div>
      <div className="prose max-w-full">{parse(data.description)}</div>
      <div className="flex justify-between items-center mt-12">
        <div className="flex space-x-3">
          <button
            className="flex items-center text-gray-500 text-sm"
            onClick={scrollToComments}
          >
            <CommentIcon /> COMMENTS
          </button>
          <button
            className="flex items-center text-gray-500 text-sm"
            onClick={handleShareClick}
          >
            <ShareIcon /> SHARE
          </button>
        </div>
      </div>
      <div ref={commentsRef} className="border-y py-4">
        <div className="mb-2 pb-2 border-b flex justify-between">
          <p className="text-xl font-bold ">Comments</p>
          <button
            className="flex items-center text-white bg-black rounded-md px-4 py-1 text-sm"
            onClick={handelCommentClick}
          >
            {" "}
            Add Comment
          </button>
        </div>
        {showCommentInput && (
          <div className="mt-2 py-4 border-b">
            <textarea
              className="w-full px-3 py-2 mb-2 border rounded"
              placeholder="Your Comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
            <div className="flex space-x-2 text-sm">
              <button
                className="px-4 py-1 bg-black text-white rounded"
                onClick={handleAddComment}
              >
                Add Comment
              </button>
              <button
                className="px-4 py-1 white text-black rounded border border-black"
                onClick={() => setShowCommentInput(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {data?.comments.length > 0 ? (
          data.comments.map((comment, index) => (
            <div key={index} className="flex flex-col items-start mt-2">
              <div className="flex items-center space-x-2 mb-1">
                <AccountCircleIcon className="text-gray-600" />
                <p className="text-sm font-semibold">{comment.user}</p>
              </div>
              <div className="ml-8">
                <p className="text-xs text-gray-500">{comment.text}</p>
              </div>
            </div>
          ))
        ) : (
          <p className="text-sm text-gray-500 mt-2">No comments</p>
        )}
      </div>
      <RelatedNews newsData={newsData} newsId={newsId} />
      {/* <div className='border-b py-4'>
                            <p className="text-xl font-bold mb-2">Related Topics</p>
                            <ul className="list-disc ml-5">
                                {relatedTopics.map((topic, index) => (
                                    <li key={index} className=" underline">{topic}</li>
                                ))}
                            </ul>
                        </div> */}
      {/* <div className='border-b py-4'>
                            <p className="text-xl font-bold mb-3">Trending in Companies</p>
                            <div className="grid grid-cols-3 gap-2">
                                {trendingCompanies.map((company, index) => (
                                    <div key={index} className="flex items-center space-x-2">
                                        <TrendingUpIcon className='text-red-600' />
                                        <span>{company}</span>
                                    </div>
                                ))}
                            </div>
                        </div> */}
    </div>
  );
};

export default Article;
