import React from 'react';
import NewsAds from './NewsAds';
import { RelatedStories } from '../../pages/NewsPortal/data';
import { useNavigate } from 'react-router-dom';
import YoutubeEmbed from './YoutubeEmbed';

const Sidebar = ({ newsData }) => {
  const navigate = useNavigate();
  
  const sortedNewsData = newsData.sort((a, b) => new Date(b.date) - new Date(a.date));
  const topThreeNews = sortedNewsData.slice(0, 3);

  const handleClick = (id) => () => {
    console.log('Clicked on news item with id:', id);
    navigate(`/news-portal/news/${id}`);
  };

  return (
    <div className='w-[20%] sticky top-0 space-y-4 hidden md:block'>
      <div className='bg-white p-4 shadow rounded'>
        <p className="text-xl font-bold my-4">Latest News</p>
        <div className='space-y-4'>
          {Array.isArray(topThreeNews) && topThreeNews.map((newsItem, index) => (
            <div
              key={index}
              className='space-y-2 border p-2 cursor-pointer'
              onClick={handleClick(newsItem.id)}
            >
              <img
                src={newsItem.image}
                alt={newsItem.title}
                className='w-full h-32 object-cover rounded-lg'
              />
              <p className="text-sm">{newsItem.title}</p>
            </div>
          ))}
          {/* <div className='w-full h-32'>
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/tC_qwBJ1kos?si=LuLkI_eiyy-Np-Vb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div> */}
        </div>
        
      </div>
      <div className='bg-white p-4 shadow rounded'>
            <p className='text-xl font-bold my-4'>Youtube Videos</p>
           
            <YoutubeEmbed embedId="tC_qwBJ1kos" />
            <YoutubeEmbed embedId="tC_qwBJ1kos" />

            </div>
      {/* Related Stories Section */}
      {/* Uncomment and adjust if you want to include it */}
      {/* <div className='p-4 border bg-white shadow rounded'>
        <p className="text-xl font-bold mb-2">Related Stories</p>
        <div className="flex flex-col space-y-4">
          {RelatedStories.map((story, index) => (
            <div key={index} className="flex flex-col space-y-2">
              <NewsAds width="100%" height="150px" />
              <p className="text-sm">{story.title}</p>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default Sidebar;
