import React from "react";
import ShareIcon from "@mui/icons-material/Share";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";

export default function RelatedNews({ newsData, newsId }) {
  const navigate = useNavigate();
  const formatDate = (dateString) => {
    const date = parseISO(dateString);

    return format(date, "d MMMM 'at' h:mm a");
  };

  const handleClick = (news) => {
    navigate(`/news-portal/news/${news.id}`);
  };

  const handleClickShare = (event, news) => {
    event.stopPropagation();
    try {
      const shareUrl = `${window.location.origin}/news-portal/news/${news.id}`;
      if (navigator.share) {
        navigator.share({
          image: news.image,
          title: news.title,
          text: news.description,
          url: shareUrl,
        });
      } else {
        navigator.clipboard.writeText(window.location.href);
        toast.info("Link copied to clipboard");
      }
    } catch (err) {
      console.error("Error sharing news:", err);
    }
  };

  return (
    <>
      <div className="mt-40 border-0">
        <p className="text-xl font-bold mt-10">Related News</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
        {newsData?.map((news, index) => (
          <div
            key={index}
            onClick={() => handleClick(news)}
            className="flex items-center justify-between py-2 px-4 bg-white cursor-pointer shadow rounded"
          >
            <div className="flex flex-col justify-between h-full py-2 border-0 w-4/5">
              <h3 className="text-base font-semibold text-gray-800">
                {news.title}
              </h3>
              <div className="flex justify-between items-center text-gray-600">
                <div>
                  <span className="text-sm mr-2">
                    {formatDate(news?.updated_at)}
                  </span>
                </div>
                <div className="space-x-4">
                  <BookmarksIcon
                    fontSize="inherit"
                    className="text-gray-600 cursor-pointer"
                  />
                  <ShareIcon
                    fontSize="inherit"
                    className="text-gray-600 cursor-pointer"
                    onClick={() => handleClickShare(news)}
                  />
                </div>
              </div>
            </div>
            <div className="w-[100px] h-[100px] ml-4 flex items-center justify-center bg-gray-100">
              {news.image ? (
                <img
                  src={news.image}
                  alt="news"
                  className="w-full h-full object-cover rounded-lg border-0 border-black"
                />
              ) : (
                <div className="w-full h-full bg-gray-300 rounded-lg"></div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
