import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LatestNewsCard from "../../components/NewsPortal/LatestNewsCard";
import NewsAds from "../../components/NewsPortal/NewsAds";

const NewsContainer = ({ newsTabs, newsData }) => {
  const [data, setData] = useState([]);
  const [label, setLabel] = useState("Latest News");
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    const selectedTab = newsTabs.find((tab) => tab.path === path);

    if (selectedTab) {
      setLabel(selectedTab.label);
    } else {
      setLabel("Latest News");
    }
  }, [location.pathname, newsTabs]);

  useEffect(() => {
    if (newsData && label) {
      filterNews();
    }
  }, [newsData, label]);

  const filterNews = () => {
    try {
      console.log("Filtering news for:", label);
      if (label === "Latest News") {
        setData(newsData);
      } else {
        const filteredData = newsData.filter((news) =>
          news.tags.some(
            (tagArray) =>
              Array.isArray(tagArray) &&
              tagArray[0] &&
              typeof tagArray[0] === "string" &&
              tagArray[0].toLowerCase().includes(label.toLowerCase())
          )
        );
        setData(filteredData);
      }
    } catch (error) {
      console.error("Error filtering news:", error);
    }
  };

  return (
    <div className="md:w-[78%] w-full flex flex-col space-y-4 min-h-[800px] ">
      {data.length > 0 ? (
        data.map((news, index) => (
          <React.Fragment key={index}>
            <LatestNewsCard {...news} />
            {(index + 1) % 3 === 0 && <NewsAds width="100%" height="150px" />}
          </React.Fragment>
        ))
      ) : (
        <div>No news available</div>
      )}
    </div>
  );
};

export default NewsContainer;
